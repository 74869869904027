import React from "react";
import './Buttons.css';

type ActionButtonProps = {
    text: string;
    height?: string;
    width?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    onClick: (event: any) => void;
}

type FormSubmitButtonProps = {
    text: string;
    disabled?: boolean;
}

export function NormalActionButton({ text, width, height, onClick }: ActionButtonProps) {
    return (
        <button className="Normal-Action-Button" onClick={onClick} style={{width, height}}>
            {text}
        </button>
    );
}

export function ConfirmActionButton({ text, style, onClick }: ActionButtonProps) {
    return (
        <button className="Confirm-Action-Button" onClick={onClick} style={style}>
            {text}
        </button>
    );
}

export function LowHeightActionButton({ text, onClick }: ActionButtonProps) {
    return (
        <button className="LH-Action-Button" onClick={onClick}>
            {text}
        </button>
    );
}

export const FormSubmitButton = React.forwardRef<HTMLButtonElement, FormSubmitButtonProps>(
    function FormSubmitButton({ text, disabled }, ref) {
    return (
        <button type="submit" className="Form-Submit-Button" disabled={disabled} ref={ref}>
            {text}
        </button>
    );
});