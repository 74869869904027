import React from "react";
import LoadingIcon from "./Components/LoadingIcon";
import './RedeemCode.css';
import ErrorMessage from "./Components/ErrorMessage";
import AnimatedCheckmark from "./AnimatedCheckmark";

export default function RedeemCode() {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);
  const appWindowContent = React.useRef<HTMLDivElement>(null);
  const codeInput = React.useRef<HTMLInputElement>(null);
  const submitInput = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    setTimeout(() => {
      if (!appWindowContent.current) return;

      appWindowContent.current.style.opacity = "1";
    }, 10);
  }, []);

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setLoading(true);
    try {
      const recaptchaToken = await grecaptcha.execute(ReCaptchaSiteKey, { action: "redeem_code" });

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/account/redeem_code`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          code: codeInput.current?.value,
          grecaptcha: recaptchaToken
        })
      });
      const data = await response.json();

      if (!data.success) {
        throw new Error(data.result_message);
      }

      setSuccess(true);
    } catch (err) {
      setErrorMsg((err as Error).message);
    } finally {
      validateValues();
      setLoading(false);
    }
  }

  function validateValues() {
    if (!submitInput.current) return;

    const code = codeInput.current?.value;

    if (!code) {
      submitInput.current.disabled = true;
      return;
    }

    submitInput.current.disabled = false;
  }

  return (
    <section className="App-Window">
      <div className="App-Window-Content" style={{opacity: 0}} ref={appWindowContent}>
        <h1 className="App-Window-Title">Redeem Code</h1>
        <div className="Redeem-Code-Content">
          <p className="Redeem-Code-Description">Enter your code below to redeem your items.</p>
          <form style={{ maxWidth: "304px", marginInline: "auto" }} onSubmit={onSubmit}>
            <input
              type="text"
              name="code"
              placeholder="Promo Code"
              className="Form-Input"
              autoComplete="off"
              onChange={validateValues}
              ref={codeInput} />
            <input type="submit" value="Redeem" className="Form-Submit" disabled ref={submitInput} />
          </form>
        </div>
      </div>
      {success && (
        <div className="Redeem-Code-Success-Modal-Wrapper" onClick={() => setSuccess(false)}>
          <div className="Redeem-Code-Success-Modal" onClick={(ev) => ev.stopPropagation()}>
            <AnimatedCheckmark/>
            <p className="Redeem-Code-Success">
              Your code has been redeemed successfully!<br/>
              Find your new items in your item claim.
            </p>
            <p className="Redeem-Code-Dismiss-Text">Click outside to dismiss</p>
          </div>
        </div>
      )}
      {loading && <LoadingIcon />}
      {errorMsg && <ErrorMessage message={errorMsg} handleDismiss={() => setErrorMsg(null)} />}
    </section>
  );
}