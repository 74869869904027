export function validateEmail(emailRef: FormInputRef): string | null {
    const email = emailRef.getValue();

    if (!email || email.length > 256) {
        return "Invalid E-Mail Address";
    }

    const emailTester = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    if (!emailTester.test(email)) {
        return "Invalid E-Mail Address";
    }

    const [account, address] = email.split('@');
    if (account.length > 64) {
        return "Invalid E-Mail Address";
    }

    const domainParts = address.split('.');
    if (domainParts.some(function (part: string) {
        return part.length > 63;
    })) {
        return "Invalid E-Mail Address";
    }

    return null;
}

export function validateVerificationCode(codeRef: FormInputRef): string | null {
    const code = codeRef.getValue();
    if (!code) {
        return "Verification Code is required.";
    }

    const codeTester = /^[0-9]{8}$/;
    if (!codeTester.test(code)) {
        return "Invalid Verification Code.";
    }

    return null;
}

export function validatePassword(passwordRef: FormInputRef): string | null {
    const password = passwordRef.getValue();

    if (!password) {
        return "Password is required.";
    }

    if (password.length < 8 || password.length > 32) {
        return "Password must be between 8-32 characters.";
    }

    const passwordTester = /^(?=.*\d)(?=.*[a-z]).{8,}$/;
    if (!passwordTester.test(password)) {
        return "Password must contain at least 1 letter and 1 number.";
    }

    return null;
}

export function validateConfirmPassword(
    passwordRef: FormInputRef,
    confirmPasswordRef: FormInputRef): string | null {

    if (passwordRef.getValue() !== confirmPasswordRef.getValue()) {
        return "Passwords do not match.";
    }

    return null;
}