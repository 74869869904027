import React from "react";
import './TwitchDrops.css';
import LoadingIcon from "./Components/LoadingIcon";
import ErrorMessage from "./Components/ErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";
import { faArrowUpRightFromSquare, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

type TwitchUserStats = {
  account: {
    twitch_id: string;
    login: string;
    expires_at: string;
  } | null;
  campaigns: {
    id: number;
    name: string;
    starts_at: Date;
    expires_at: Date;
    rewards: {
      id: number;
      name: string;
      item_id: number | null;
      item_amount: number | null;
      mtc: number | null;
      required_time: number;
      icon_url: string | null;
    }[];
    watchtime: number;
  }[];
};

export default function TwitchDrops() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);
  const [twitchStats, setTwitchStats] = React.useState<TwitchUserStats | null>(null);
  const appWindowContent = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const opacityTimer = setTimeout(() => {
      if (!appWindowContent.current) return;

      appWindowContent.current.style.opacity = "1";
    }, 10);

    const abortCtrl = new AbortController();
    const fetchTimer = setInterval(fetchData, 30 * 60 * 1000); // 1 minute

    async function fetchData() {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/twitch/retrieve_user_stats`, {
          credentials: 'include',
          signal: abortCtrl.signal
        });
        const data = await response.json();

        if (!data.success) {
          throw new Error(data.result_message);
        }

        data.campaigns.forEach((campaign: TwitchUserStats["campaigns"][0]) => {
          campaign.starts_at = new Date(campaign.starts_at);
          campaign.expires_at = new Date(campaign.expires_at);
        });

        setTwitchStats({
          account: data.account,
          campaigns: data.campaigns
        });
      } catch (err) {
        if (!(err instanceof DOMException) || err.name !== "AbortError") {
          setErrorMsg((err as Error).message);
        }
      } finally {
        setLoading(false);
      }
    }

    fetchData();

    return () => {
      clearTimeout(opacityTimer);
      clearInterval(fetchTimer);
      abortCtrl.abort();
    };
  }, []);

  function onDisconnectClick() {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API_BASE_URL}/twitch/user_disconnect`, {
      method: "DELETE",
      credentials: "include"
    })
    .then(response => response.json())
    .then(data => {
      if (!data.success) {
        throw new Error(data.result_message);
      }

      setTwitchStats(twitchStats && {
        account: null,
        campaigns: twitchStats.campaigns
      });

      setErrorMsg("Twitch account has been disconnected.");
    })
    .catch(err => {
      setErrorMsg((err as Error).message);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  return (
    <section className="App-Window" style={{ minHeight: "340px", backgroundColor: "rgb(247, 247, 248)" }}>
      <div className="App-Window-Content" style={{ opacity: 0 }} ref={appWindowContent}>
        <h1 className="App-Window-Title">Twitch Drops</h1>
        <div className="Twitch-Drops-Content">
          <p className="Twitch-Drops-Description">
            Twitch Drops are a way to earn in-game rewards by watching our partnered streamers on
            Twitch. To get started, link your Twitch account to your MT: The Dream account and start
            watching our partnered streamers. You can earn rewards such as in-game currency, skins,
            and more!
          </p>
          {twitchStats && <UserStats stats={twitchStats} onDisconnectClick={onDisconnectClick} />}
        </div>
      </div>
      {loading && <LoadingIcon />}
      {errorMsg && <ErrorMessage message={errorMsg} handleDismiss={() => setErrorMsg(null)} />}
    </section>
  );
}

function UserStats({ stats, onDisconnectClick }: { stats: TwitchUserStats; onDisconnectClick: () => void; }) {
  return (
    <>
      <div className="Twitch-Account-Container">
        <div className="Account-Setting-Icon Twitch-Icon">
          <FontAwesomeIcon icon={faTwitch} color="white" />
        </div>
        <div className="Twitch-Account-Content">
          <p className="Twitch-Account-Content-Title">Connected Account</p>
          <p className="Twitch-Account-Content-Value">{stats.account?.login || "None"}</p>
        </div>
        {!stats.account && <TwitchExternalButton href={`${process.env.REACT_APP_API_BASE_URL}/twitch/user_connect`} text="Connect" />}
        {stats.account && <TwitchPurpleButton text="Disconnect" onClick={onDisconnectClick} />}
      </div>
      <h3 className="Twitch-Campaigns-List-Title">Campaigns</h3>
      <ul className="Twitch-Campaigns-List">
        {stats.campaigns.map((campaign) => <TwitchCampaign key={campaign.id} campaign={campaign} />)}
      </ul>
    </>
  );
}

enum TwitchCampaignStatus {
  Upcoming = "Upcoming",
  Active = "Active",
  Ended = "Ended"
}

function TwitchCampaign({ campaign }: { campaign: TwitchUserStats["campaigns"][0] }) {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const currentDate = new Date();
  let status: TwitchCampaignStatus;

  if (campaign.starts_at > currentDate) {
    status = TwitchCampaignStatus.Upcoming;
  } else if (campaign.expires_at < currentDate) {
    status = TwitchCampaignStatus.Ended;
  } else {
    status = TwitchCampaignStatus.Active;
  }

  return (
    <li className="Twitch-Campaign">
      <div className="Twitch-Campaign-Title-Container" onClick={() => setExpanded(!expanded)}>
        <p className="Twitch-Campaign-Title">{campaign.name}</p>
        <div className="Twitch-Campaign-Status">
          <TwitchStatusLabel status={status} statusText={status.toString()} />
        </div>
        <p className="Twitch-Campaign-Period">
          {campaign.starts_at.toLocaleString()} ~ {campaign.expires_at.toLocaleString()}
        </p>
        <div className="Twitch-Campaign-Expand-Button">
          <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
        </div>
      </div>
      {expanded && <div className="Twitch-Campaign-Expanded-Container">
        <section className="Twitch-Campaign-Details-Section">
          <p className="Twitch-Campaign-Details-Section-Title">Rewards</p>
          <ul className="Twitch-Campaign-Rewards-List">
            {campaign.rewards.map((reward) => (
              <TwitchReward key={reward.id} watchtime={campaign.watchtime} reward={reward} />
            ))}
          </ul>
        </section>
        <section className="Twitch-Campaign-Details-Section">
          <p className="Twitch-Campaign-Details-Section-Title">How to Earn the Drop</p>
          <ol style={{ paddingLeft: "1rem" }}>
            <li>Link your Twitch account to your MT: The Dream account.</li>
            <li>Watch partnered streamers on Twitch for the required time.</li>
            <li>Rewards will be automatically sent to your account via Item Claim.</li>
          </ol>
        </section>
        <section className="Twitch-Campaign-Details-Section">
          <p className="Twitch-Campaign-Details-Section-Title">Partnered Streamers</p>
          <ul style={{ paddingLeft: "1rem" }}>
            <li><a href="https://twitch.tv/evilbison" className="Twitch-Link" target="_blank" rel="noreferrer">evilbison</a></li>
            <li><a href="https://twitch.tv/plusultrabacon" className="Twitch-Link" target="_blank" rel="noreferrer">plusultrabacon</a></li>
            <li><a href="https://twitch.tv/wofwof_tv" className="Twitch-Link" target="_blank" rel="noreferrer">wofwof_tv</a></li>
            <li><a href="https://twitch.tv/kilryan" className="Twitch-Link" target="_blank" rel="noreferrer">kilryan</a></li>
            <li><a href="https://twitch.tv/kalipso" className="Twitch-Link" target="_blank" rel="noreferrer">kalipso</a></li>
            <li><a href="https://twitch.tv/peasancarrots" className="Twitch-Link" target="_blank" rel="noreferrer">PeasAnCarrots</a></li>
            <li><a href="https://twitch.tv/kawaakaritv" className="Twitch-Link" target="_blank" rel="noreferrer">KawaakariTV</a></li>
            <li><a href="https://twitch.tv/alina__nyan" className="Twitch-Link" target="_blank" rel="noreferrer">Alina__Nyan</a></li>
            <li><a href="https://www.twitch.tv/sarpsorp" className="Twitch-Link" target="_blank" rel="noreferrer">sarpsorp</a></li>
          </ul>
        </section>
      </div>}
    </li>
  );
}

function TwitchReward({ watchtime, reward }: { watchtime: number; reward: TwitchUserStats["campaigns"][0]["rewards"][0] }) {
  let progress = Math.trunc((watchtime / reward.required_time) * 100);
  progress = progress > 100 ? 100 : progress;

  const total = (reward.required_time / 60).toFixed(1);

  return (
    <li className="Twitch-Campaign-Reward">
      <div className="Twitch-Campaign-Reward-Details">
        <div className="Twitch-Campaign-Reward-Icon">
          {reward.icon_url && <img src={reward.icon_url} alt={reward.name} />}
        </div>
        <p className="Twitch-Campaign-Reward-Name">{reward.name}</p>
      </div>
      <div className="Twitch-Campaign-Reward-Watchtime">
        <div className="Twitch-Campaign-Reward-ProgressBar">
          <div className="Twitch-Campaign-Reward-Progress" style={{ width: `${progress}%` }} />
        </div>
        <p className="Twitch-Campaign-Reward-ProgressText">{progress}% of {total} hours</p>
      </div>
    </li>
  );
}

function TwitchPurpleButton({ text, disabled, onClick }: { text: string; disabled?: boolean; onClick: () => void; }) {
  return (
    <button className="Twitch-Purple-Button" onClick={onClick} disabled={disabled}>{text}</button>
  );
}

function TwitchExternalButton({ href, text }: { href: string; text: string; }) {
  return (
    <button className="Twitch-Purple-Button" onClick={() => {
      window.location.href = href;
    }}><FontAwesomeIcon icon={faArrowUpRightFromSquare} pull="left" /> {text}</button>
  );
}

function TwitchStatusLabel({ status, statusText }: { status: TwitchCampaignStatus; statusText: string; }) {
  const statusClass =
    status === TwitchCampaignStatus.Active ? "Twitch-Status-Positive" :
      status === TwitchCampaignStatus.Upcoming ? "Twitch-Status-Neutral" : "Twitch-Status-Negative";

  return (
    <div className={`Twitch-Status-Label ${statusClass}`}>{statusText}</div>
  );
}