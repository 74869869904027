import React from 'react';
import './App.css';
import Shop from './Shop';
import LoginWindow from './LoginWindow';
import LoadingIcon from './Components/LoadingIcon';
import RegisterWindow from './RegisterWindow';
import { LoginContext } from './Contexts';
import Header from './Header';
import PasswordResetWindow from './PasswordResetWindow';
import RedeemCode from './RedeemCode';
import AccountSettings from './AccountSettings';
import PaymentSuccess from './PaymentSuccess';
import TwitchDrops from './TwitchDrops';

type Page = {
  window: AppWindow;
  requireLoggedIn: boolean;
}

type Pages = {
  [key: string]: Page;
}

const PAGES: Pages = {
  "/login": {
    window: "login",
    requireLoggedIn: false
  },
  "/register": {
    window: "register",
    requireLoggedIn: false
  },
  "/passwordreset": {
    window: "passwordreset",
    requireLoggedIn: false
  },
  "/donate": {
    window: "shop",
    requireLoggedIn: true
  },
  "/account": {
    window: "account",
    requireLoggedIn: true
  },
  "/redeemcode": {
    window: "redeemcode",
    requireLoggedIn: true
  },
  "/payment_successful": {
    window: "payment_successful",
    requireLoggedIn: true
  },
  "/twitchdrops": {
    window: "twitch_drops",
    requireLoggedIn: true
  }
};

export default function App() {
  const [account, setAccount] = React.useState<AccountDetails | null>(null);
  const [activeWindow, setActiveWindow] = React.useState<AppWindow | null>(null);

  React.useEffect(() => {
    const abortCtrl = new AbortController();

    async function fetchData() {
      const page = PAGES[window.location.hash.substring(1)];

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/account/retrieve_account_data`, {
          credentials: 'include',
          signal: abortCtrl.signal
        });
        const data = await response.json();

        if(!data.success) {
          throw new Error(data.result_message);
        }

        setAccount({
          username: data.username,
          email: data.email,
          active: data.active,
          veteran: data.veteran,
          teraclub: data.teraClub,
          mtc: data.mtc,
          tfa: data.tfa ?? false,
          mg_key: data.mg_key,
        });
      } catch(err) {
        if(!(err instanceof DOMException)) {
          if(page && !page.requireLoggedIn) {
            setActiveWindow(page.window);
          } else {
            window.location.href = "/#/login";
          }
        }
      }
    }
    
    fetchData();
    
    return () => {
      abortCtrl.abort();
    };
  }, []);

  React.useEffect(() => {
    function handleHashChange(event: HashChangeEvent) {
      const page = PAGES[window.location.hash.substring(1)];

      if(!page || activeWindow === page.window) return;

      if(page) {
        if(account && !page.requireLoggedIn) {
          window.location.href = event.oldURL;
          return;
        }

        if(!account && page.requireLoggedIn) {
          window.location.href = event.oldURL;
          return;
        }

        setActiveWindow(page.window);
      }
    }

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  });

  React.useEffect(() => {
    if(!account) return;
    const page = PAGES[window.location.hash.substring(1)];

    if(page && page.requireLoggedIn) {
      setActiveWindow(page.window);
    } else {
      window.location.href = "/#/donate";
    }
  }, [account]);

  async function logout() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/logout`, {
        method: 'DELETE',
        credentials: 'include'
      });
      const data = await response.json();

      if(!data.success) {
        throw new Error(data.result_message);
      }

      setAccount(null);
      window.location.href = '/#/login';
    } catch(err) {
      console.error(err);
    }
  }

  if(!activeWindow) {
    return <div className="App"><LoadingIcon/></div>;
  }

  return (
    <div className="App">
      <LoginContext.Provider value={account}>
        <Header logoutAction={logout}/>
        <main>
          {activeWindow === 'login' && <LoginWindow setAccount={setAccount}/>}
          {activeWindow === 'register' && <RegisterWindow/>}
          {activeWindow === 'passwordreset' && <PasswordResetWindow/>}
          {activeWindow === 'shop' && <Shop/>}
          {activeWindow === 'redeemcode' && <RedeemCode/>}
          {activeWindow === 'account' && <AccountSettings/>}
          {activeWindow === 'payment_successful' && <PaymentSuccess/>}
          {activeWindow === 'twitch_drops' && <TwitchDrops/>}
        </main>
      </LoginContext.Provider>
    </div>
  );
}