import React from 'react';
import './ErrorMessage.css';

type ErrorMessageProps = {
    message: string;
    handleDismiss: () => void;
}

export default function ErrorMessage({ message, handleDismiss }: ErrorMessageProps) {
    React.useEffect(() => {
      if (message) {
        const timer = setTimeout(() => handleDismiss(), 5000);
        return () => clearTimeout(timer);
      }
    }, [message]);
  
    return (
      <section className="Error-Message-Container" onClick={handleDismiss}>
        <p className="Error-Message">{message}</p>
      </section>
    );
  }