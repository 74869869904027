import React from 'react';
import './LoginWindow.css';
import LoadingIcon from './Components/LoadingIcon';
import ErrorMessage from './Components/ErrorMessage';

type LoginWindowProps = {
    setAccount: React.Dispatch<React.SetStateAction<AccountDetails | null>>;
};

export default function LoginWindow({ setAccount }: LoginWindowProps) {
    const [loggingIn, setLoggingIn] = React.useState<boolean>(false);
    const [errorMsg, setErrorMsg] = React.useState<string | null>(null);
    const usernameRef = React.useRef<HTMLInputElement>(null);
    const passwordRef = React.useRef<HTMLInputElement>(null);
    const stayLoggedInRef = React.useRef<HTMLInputElement>(null);
    const submitRef = React.useRef<HTMLButtonElement>(null);

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoggingIn(true);

        try {
            const token = await grecaptcha.execute(ReCaptchaSiteKey, { action: 'login' });

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login/account`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: usernameRef.current?.value,
                    password: passwordRef.current?.value,
                    stayLoggedIn: stayLoggedInRef.current?.checked,
                    grecaptcha: token
                })
            });
            const data = await response.json();

            if(!data.success) {
                throw new Error(data.result_message);
            }

            setAccount({
                username: data.username,
                email: data.email,
                active: data.active,
                veteran: data.veteran,
                teraclub: data.teraclub,
                mtc: data.mtc,
                tfa: data.tfa ?? false,
                mg_key: data.mg_key,
            });
            window.location.href = '/#/donate';
        } catch (err) {
            setErrorMsg((err as Error).message);

            if(passwordRef.current?.value) {
                passwordRef.current.value = "";
                if(!submitRef.current) return;
                submitRef.current.disabled = true;
            }
        } finally {
            setLoggingIn(false);
        }
    }

    function validateValues() {
        if(!submitRef.current) return;

        if(!usernameRef.current?.value || !passwordRef.current?.value) {
            submitRef.current.disabled = true;
            return;
        }

        submitRef.current.disabled = false;
    }

    return (
        <section className="Login-Window">
            <div className="Login-Title"><h2>Account Log In</h2></div>
            <div className="Login-Content">
                <form onSubmit={handleSubmit}>
                    <input type="text" name="username" className="Form-Input" placeholder="Username" autoComplete="off" ref={usernameRef} onChange={validateValues}/>
                    <input type="password" name="password" className="Form-Input" placeholder="Password"ref={passwordRef} onChange={validateValues}/>
                    <input type="checkbox" id="stayloggedin" name="stayloggedin" ref={stayLoggedInRef} style={{marginLeft: '0'}}/>
                    <label htmlFor="stayloggedin" className="Stay-Logged-In">Stay Logged In</label>
                    <button type="submit" className="Form-Submit" ref={submitRef} disabled>Log In</button>
                    <div className="Form-Links">
                        <a href="/#/passwordreset">Forgot Password?</a>
                        <a href="/#/register">Create New Account</a>
                    </div>
                </form>
            </div>
            {loggingIn && <LoadingIcon/>}
            {errorMsg && <ErrorMessage message={errorMsg} handleDismiss={() => setErrorMsg(null)}/>}
        </section>
    );
}