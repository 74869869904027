import './Shop.css';
import ChronoscrollImg from './Resources/chronoscroll.png';
import CoinsImg from './Resources/coins.png';
import TikatImg from './Resources/tcat.png';
import BlessingImg from './Resources/blessing.png';
import BaldersBlessing from './Resources/images/baldersblessing.png';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ErrorMessage from './Components/ErrorMessage';
import LoadingIcon from './Components/LoadingIcon';
import { LoginContext } from './Contexts';

const SHOP_CATEGORIES: { [key: string]: number } = {
  'veteran': 1,
  'teraclub': 2,
  'tikat': 3,
  'mtc': 4,
  'blessing': 5
};

type ShopAPIPackageCache = {
  [category: string]: ShopAPIPackage[];
};

export default function Shop() {
  const [selectedCategory, setSelectedCategory] = React.useState<string | null>(null);
  const [visibleCategory, setVisibleCategory] = React.useState<string | null>(null);
  const [fetchingInProgress, setFetchingInProgress] = React.useState<boolean>(false);
  const [availablePackages, setAvailablePackages] = React.useState<ShopAPIPackage[] | null>(null);
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);
  const cache = React.useRef<ShopAPIPackageCache>({});
  const shopRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if(!selectedCategory || selectedCategory === 'blessing') return;

    if(cache.current[selectedCategory]) {
      setAvailablePackages(cache.current[selectedCategory]);
      return;
    }

    setFetchingInProgress(true);
    const abortCtrl = new AbortController();

    const fetchData = async function () {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/shop/category/${SHOP_CATEGORIES[selectedCategory]}/products`, {
          credentials: 'include',
          signal: abortCtrl.signal
        });
        const data = await response.json();
        if(!data.success) {
          if(data.result_code === 401) {
            window.location.href = '/';
            return;
          }
          throw new Error(data.result_message);
        }

        cache.current[selectedCategory] = data.available_shop_products;
        setAvailablePackages(data.available_shop_products);
      } catch (error) {
        if(!(error instanceof DOMException) || error.name !== "AbortError") {
          setErrorMsg((error as Error).message);
          switchCategory(null);
        }
      } finally {
        setFetchingInProgress(false);
      }
    }

    fetchData();

    return () => {
      abortCtrl.abort();
    }
  }, [selectedCategory]);

  React.useEffect(() => {
    setTimeout(() => {
      if(shopRef.current) {
        shopRef.current.style.opacity = "1";
      }
    }, 10);
  }, []);

  function switchCategory(category: string | null) {
    setSelectedCategory(category);

    setTimeout(() => {
      if(shopRef.current) {
        shopRef.current.style.opacity = "0";
        setTimeout(() => {
          setVisibleCategory(category);

          if(shopRef.current)
            shopRef.current.style.opacity = "1";
        }, 400);
      }
    }, 10);
  }

  return (
    <section className="App-Window">
      <div className="Shop" ref={shopRef}>
        {!visibleCategory && (
          <>
            <h1 className="Shop-Title">Donate</h1>
            <ShopPromotion startDate={new Date("2024-08-31 18:00:00 UTC")} endDate={new Date("2024-09-04 00:00:00 UTC")} switchCategory={switchCategory}/>
            <ul className="Shop-Category-List">
              <ShopCategoryListItem title="Balder&apos;s Blessing" img={BlessingImg} flag="NEW" onClick={() => switchCategory('blessing')} />
              <ShopCategoryListItem title="Starting Package" img={ChronoscrollImg} veteran onClick={() => switchCategory('veteran')} />
              <ShopCategoryListItem title="Dream Club" img={ChronoscrollImg} onClick={() => switchCategory('teraclub')} />
              <ShopCategoryListItem title="MT Coins" img={CoinsImg} onClick={() => switchCategory('mtc')} />
              <ShopCategoryListItem title="Astrum Coin" img={TikatImg} onClick={() => switchCategory('tikat')} />
            </ul>
          </>
        )}
        {visibleCategory === 'blessing' && (
          <ShopCategoryBlessing
            fetchingItems={fetchingInProgress}
            title="Balder&apos;s Blessing"
            img={BlessingImg}
            switchCategory={switchCategory}/>
        )}
        {visibleCategory === 'veteran' && (
          <ShopCategory
            items={availablePackages}
            fetchingItems={fetchingInProgress}
            title="Starting Package"
            img={ChronoscrollImg}
            bottomDesc={[
              "This is a one time donation package.",
              "If you already have Dream Club active, it will be extended by 30 days.",
              "Astrum Coin will appear in your Item Claim inside the game.",
              "This package will <strong>set</strong> your character slots to 8 and bank tabs to 4. It will not increase your character slots by 8 or bank tabs by 4."
            ]}
            switchCategory={switchCategory}/>
        )}
        {visibleCategory === 'teraclub' && (
          <ShopCategory
            items={availablePackages}
            fetchingItems={fetchingInProgress}
            title="Get Dream Club"
            img={ChronoscrollImg}
            bottomDesc={["If you already have Dream Club active, it will be extended by the amount of days the package of your choice provides."]}
            switchCategory={switchCategory}/>
        )}
        {visibleCategory === 'mtc' && (
          <ShopCategory
            items={availablePackages}
            fetchingItems={fetchingInProgress}
            title="Top-Up MT Coins"
            img={CoinsImg}
            bottomDesc={["50% More Coins for first donation per package!"]}
            switchCategory={switchCategory}/>
        )}
        {visibleCategory === 'tikat' && (
          <ShopCategory
            items={availablePackages}
            fetchingItems={fetchingInProgress}
            title="Top-Up Astrum Coin"
            img={TikatImg}
            bottomDesc={["Obtained Astrum Coin will appear in your Item Claim inside the game."]}
            switchCategory={switchCategory}/>
          )}
        {errorMsg && <ErrorMessage message={errorMsg} handleDismiss={() => setErrorMsg(null)} />}
      </div>
    </section>
  );
}

function ShopCategory({ items, fetchingItems, title, img, topDesc, bottomDesc, switchCategory }: ShopCategoryProps) {
  const [selectedPackage, setSelectedPackage] = React.useState<number | null>(null);
  
  return (
    <>
      <div className="Shop-Category-Title-Container">
        <span className="Shop-Category-BackButton" onClick={() => switchCategory(null)}><FontAwesomeIcon icon={faArrowLeft} /></span>
        <img className="Shop-Category-Image" src={img} alt={title} />
        <h3 className="Shop-Category-Title">{title}</h3>
      </div>
      <ShopPromotion startDate={new Date("2024-08-31 18:00:00 UTC")} endDate={new Date("2024-09-04 00:00:00 UTC")} switchCategory={switchCategory}/>
      {topDesc?.map((entry: string, i: number) => <p key={i} className="Shop-Category-TopDesc">{entry}</p>)}
      {fetchingItems && <div className="Loading-Container"><LoadingIcon/></div>}
      {!fetchingItems && (
        <ul className="Shop-Package-List">
          {items?.map((entry: ShopAPIPackage, i: number) => {
            return <ShopPackage
              key={i}
              title={entry.title}
              img={img}
              oldPrice={entry.oldPrice}
              price={entry.price}
              bonus={entry.bonus}
              valueFlag={entry.valueFlag}
              onClick={() => setSelectedPackage(entry.id)}
              selected={selectedPackage === entry.id} />;
          })}
        </ul>
      )}
      <div className="Shop-Payment-Buttons-Container">
        {selectedPackage && <StripeButton packageId={selectedPackage} />}
      </div>
      <div className="Shop-Category-BottomDesc">{bottomDesc?.map((entry: string, i: number) => <p key={i} dangerouslySetInnerHTML={{__html: entry}}/>)}</div>
    </>
  );
}

function ShopCategoryBlessing({ img, title, bottomDesc, switchCategory }: ShopCategoryProps){
  const [fetching, setFetching] = React.useState<boolean>(true);
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);
  const [isSubscriptionActive, setSubscriptionActive] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    const abortCtrl = new AbortController();

    const fetchData = async function () {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/shop/subscription/${process.env.REACT_APP_BLESSING_PACKAGE_ID}/status`, {
          credentials: 'include',
          signal: abortCtrl.signal
        });
        const data = await response.json();
        if(!data.success) {
          if(data.result_code === 401) {
            window.location.href = '/';
            return;
          }
          throw new Error(data.result_message);
        }

        setSubscriptionActive(data.active);
      } catch (error) {
        if(!(error instanceof DOMException) || error.name !== "AbortError") {
          setErrorMsg((error as Error).message);
          switchCategory(null);
        }
      } finally {
        setFetching(false);
      }
    }

    fetchData();

    return () => {
      abortCtrl.abort();
    }
  }, []);

  async function onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    if(fetching) {
      event.preventDefault();
      return;
    }

    setFetching(true);
  }

  return (
    <>
      <div className="Shop-Category-Title-Container">
        <span className="Shop-Category-BackButton" onClick={() => switchCategory(null)}><FontAwesomeIcon icon={faArrowLeft} /></span>
        <img className="Shop-Category-Image" src={img} alt={title} />
        <h3 className="Shop-Category-Title">{title}</h3>
      </div>
      <ShopPromotion startDate={new Date("2024-05-11 08:00:00 UTC")} endDate={new Date("2024-05-14 07:00:00 UTC")} switchCategory={switchCategory}/>
      <div className="Shop-Blessing-of-Balder-Wrapper">
        <img src={BaldersBlessing} alt="Balder's Blessing" style={{width: '100%'}} />
      </div>
      <div className="Shop-Payment-Buttons-Container" style={{marginBottom: '0'}}>
        {isSubscriptionActive === null && <LoadingStripeButton />}
        {isSubscriptionActive === false && process.env.REACT_APP_BLESSING_PACKAGE_ID && <StripeButton packageId={Number(process.env.REACT_APP_BLESSING_PACKAGE_ID)} />}
        {isSubscriptionActive === true && <div className="Shop-Subscription-Active-Msg">
          <p style={{marginBottom: '5px'}}>You have an active subscription to Balder&apos;s Blessing.</p>
          <form method="GET" action={`${process.env.REACT_APP_API_BASE_URL}/account/stripe_customer_portal_session`} onSubmit={onFormSubmit}>
            <button type="submit" className="Confirm-Action-Button" style={{width: '200px'}}>
              {!fetching && <>Manage Subscription</>}
              {fetching && <LoadingIcon position="relative" boxContainer={false} />}
            </button>
            <input type="hidden" name="return_to" value={window.location.href} />
          </form>
        </div>}
      </div>
      <div className="Shop-Category-BottomDesc">{bottomDesc?.map((entry: string, i: number) => <p key={i} dangerouslySetInnerHTML={{__html: entry}}/>)}</div>
      {errorMsg && <ErrorMessage message={errorMsg} handleDismiss={() => setErrorMsg(null)} />}
    </>
  );
}

function ShopCategoryListItem({ img, title, info, veteran, flag, onClick }: ShopCategoryListItemProps) {
  const account = React.useContext<AccountDetails | null>(LoginContext);
  
  return (
    <li className={
      `Shop-Category-List-Item${(veteran && account?.veteran) ? " disabled" : ""}`
    } onClick={(!veteran || !account?.veteran) ? onClick : undefined}>
      {flag && <div className="Shop-Package-ValueFlag">{flag}</div>}
      <div className="Shop-Category-List-Item-Inner">
        <img src={img} className="Shop-Category-List-Image" alt={title} />
        <p className="Shop-Category-List-Title">{title}</p>
        {info && <p className="Shop-Category-List-Info">{info}</p>}
      </div>
    </li>
  );
}

function ShopPackage({ img, title, oldPrice, price, bonus, selected, valueFlag, onClick }: ShopPackageProps) {
  return (
    <li className={"Shop-Package-Container" + (selected ? " selected" : "")} onClick={onClick}>
      <div className="Shop-Package">
        <div className="Shop-Package-ValueFlag">{valueFlag && <>One-Time<br/>Special</>}</div>
        <div className="Shop-Package-Details">
          <img src={img} className="Shop-Package-Image" alt={title} />
          <p className="Shop-Package-Title">{title}</p>
          <p className="Shop-Package-Price">
            {oldPrice ? (<><del>{oldPrice}</del>&nbsp;<ins>{price}</ins></>) : price} €
          </p>
        </div>
      </div>
      {bonus && (
        <div className="Shop-Package-Bonus">+ {bonus} bonus</div>
      )}
    </li>
  );
}

function LoadingStripeButton() {
  return (
    <div className="Stripe-Button-Container">
      <button type="button" className="Stripe-Button" disabled>
        <LoadingIcon position="relative" boxContainer={false} />
      </button>
    </div>
  );
}

function StripeButton({ packageId }: PaymentButtonProps) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);

  async function onStripeButtonClick() {
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/shop/create_checkout_session`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ packageId })
      });
      const data = await response.json();

      if(!data.success) {
        throw new Error(data.message);
      }

      window.location.href = data.url;
    } catch (err) {
      setErrorMsg((err as Error).message);
      setLoading(false);
    }
  }

  return (
    <>
      <div className="Stripe-Button-Container">
        <button type="button" className="Stripe-Button" onClick={onStripeButtonClick}>Donate Now</button>
      </div>
      {loading && <LoadingIcon position="fixed" />}
      {errorMsg && <ErrorMessage message={errorMsg} handleDismiss={() => setErrorMsg(null)} />}
    </>
  );
}

type TimeLeft = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

function ShopPromotion({ startDate, endDate, switchCategory }: { startDate: Date, endDate: Date, switchCategory: (category: string | null) => void }) {
  const [timeLeft, setTimeLeft] = React.useState<TimeLeft>(calculateTimeLeft(endDate));

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);

    return () => clearTimeout(timer);
  });

  const d = new Date();
  if(d < startDate || d >= endDate) return null;

  return (
    <div className="Shop-Promotion">
      <p style={{marginBottom: '5px'}}>Celebrating 3 years of MT: The Dream with <span style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }} onClick={() => switchCategory('mtc')}>30% more MT Coins on all packages!</span></p>
      <p>
        Offer expires in
        {timeLeft.days > 0 && <span> {timeLeft.days} days</span>}
        {timeLeft.hours > 0 && <span> {timeLeft.hours} hours</span>}
        {timeLeft.minutes > 0 && <span> {timeLeft.minutes} minutes</span>}
        {timeLeft.seconds > 0 && <span> {timeLeft.seconds} seconds</span>}!
      </p>
    </div>
  );
}

function calculateTimeLeft(date: Date): TimeLeft {
  const difference = (new Date(date)).getTime() - Date.now();
  let timeLeft: TimeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

  timeLeft = {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60)
  };

  return timeLeft;
}