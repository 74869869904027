import React from "react";
import "./AccountSettings.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faEnvelope, faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import DiscordIcon from './Resources/icons/discord.svg';
import { LoginContext } from "./Contexts";
import { FormSubmitButton, LowHeightActionButton, NormalActionButton } from "./Components/Buttons";
import ModalWindow from "./Components/ModalWindow";
import { FormInput, RequestVerificationCodeAPIError, VerificationCodeInput } from "./Components/FormInputs";
import LoadingIcon from "./Components/LoadingIcon";
import ErrorMessage from "./Components/ErrorMessage";
import { EmailVerificationModal } from "./EmailVerificationModal";
import { validateConfirmPassword, validateEmail, validatePassword, validateVerificationCode } from "./Utils/Validators";
import ConfirmActionModal from "./Components/ConfirmActionModal";
import ShinraInstructionPic1 from './Resources/images/shinra_upload_instructions/screen1.png';
import ShinraInstructionPic2 from './Resources/images/shinra_upload_instructions/screen2.png';
import ShinraInstructionPic3 from './Resources/images/shinra_upload_instructions/screen3.png';
import ShinraInstructionPic4 from './Resources/images/shinra_upload_instructions/screen4.png';

type AccountConnection = "discord";

const DPS_UPLOAD_URL = "https://moongourd.mtdream.net/api/upload";
const ALLOWED_AREA_URL = "https://moongourd.mtdream.net/api/whitelist";
const GLYPH_UPLOAD_URL = "https://moongourd.mtdream.net/api/upload_glyph";

export default function AccountSettings() {
  const [securityModal, setSecurityModal] = React.useState<SecurityVerificationPurpose | null>(null);
  const [changeEmailModal, setChangeEmailModal] = React.useState<string | null>(null);
  const [changePasswordModal, setChangePasswordModal] = React.useState<string | null>(null);

  const account = React.useContext(LoginContext);

  function changeEmailAction() {
    setSecurityModal("change_email");
  }

  function changePasswordAction() {
    setSecurityModal("change_password");
  }

  function onVerificationSuccess(token: string) {
    switch(securityModal) {
      case "change_email":
        setChangeEmailModal(token);
        break;
      case "change_password":
        setChangePasswordModal(token);
        break;
    }

    setSecurityModal(null);
  }

  return (
    <section className="Account-Settings-Container">
      {/* <nav className="Account-Settings-Navigation">
        <h3 className="Account-Settings-Nav-Title">Account Management</h3>
        <ul className="Account-Settings-Navigation-List">
          <li className="Account-Settings-Navigation-Item">
            <a href="#/account/settings">Account Settings</a>
          </li>
          <li className="Account-Settings-Navigation-Item">
            <a href="#/account/logindevices">Login Devices</a>
          </li>
          <li className="Account-Settings-Navigation-Item">
            <a href="#/account/shopdonations">Past Donations</a>
          </li>
        </ul>
      </nav> */}
      <div className="Account-Settings-Content">
        <h3 className="Account-Settings-Content-Title">Account Settings</h3>
        <AccountSetting icon={faUser} title="Username" value={account?.username} />
        <AccountSetting icon={faEnvelope} title="E-Mail" value={account?.email} action={changeEmailAction} />
        <AccountSetting icon={faKey} title="Password" action={changePasswordAction} />
        <MoongourdSetting/>
        {/* <AccountSetting icon={faLock} title="Two-Factor Authentication" value={account?.tfa ? "Enabled" : "Disabled"} />
        <h3 className="Account-Settings-Content-Title" style={{ paddingBlock: "20px" }}>Connected Accounts</h3>
        <div className="Account-Connections-Container">
          <AccountConnection type="discord" />
        </div>
        <div className="Account-Delete-Container">
          <h3 className="Account-Delete-Title">Delete Account</h3>
          {/ eslint-disable-next-line @typescript-eslint/no-empty-function
            <NormalActionButton text="Delete Account" onClick={() => { }} />
        </div>*/}
      </div>
      {securityModal && (
        <EmailVerificationModal
          purpose={securityModal}
          onVerificationSuccess={onVerificationSuccess}
          dismiss={() => setSecurityModal(null)} />
      )}
      {changeEmailModal && (
        <ChangeEmailModal
          token={changeEmailModal}
          dismiss={() => setChangeEmailModal(null)} />
      )}
      {changePasswordModal && (
        <ChangePasswordModal
          token={changePasswordModal}
          dismiss={() => setChangePasswordModal(null)} />
      )}
    </section>
  );
}

type AccountSettingType = {
  icon: IconDefinition;
  title: string;
  value?: string;
  action?: () => void;
  actionText?: string;
};

function AccountSetting({ icon, title, value, action, actionText }: AccountSettingType) {
  return (
    <div className="Account-Setting">
      <div className="Account-Setting-Left">
        <div className="Account-Setting-Icon">
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className="Account-Setting-Content">
          <p className="Account-Setting-Title">{title}</p>
          <p className="Account-Setting-Value">{value}</p>
        </div>
      </div>
      {action && <div className="Account-Setting-Right">
        <NormalActionButton text={actionText ?? "Change"} width="120px" onClick={action} />
      </div>}
    </div>
  )
}

function MoongourdSetting() {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [createMgApiKeyModal, setCreateMgApiKeyModal] = React.useState<boolean>(false);
  const [deleteMgApiKeyModal, setDeleteMgApiKeyModal] = React.useState<boolean>(false);
  const [showInstructionModal, setShowInstructionModal] = React.useState<boolean>(false);
  const mgKeyInputRef = React.useRef<HTMLInputElement>(null);
  const account = React.useContext(LoginContext);

  if(!account) return null;

  async function onEnableMGIntegration() {
    if(!account) return;

    setCreateMgApiKeyModal(false);
    setLoading(true);

    try {
      const token = await grecaptcha.execute(ReCaptchaSiteKey, { action: "submit" });

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/account/create_mg_api_key`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          grecaptcha: token
        })
      });
      const data = await response.json();

      if(!data.success) {
        throw new Error(data.result_message);
      }

      account.mg_key = data.api_key;
      setShowInstructionModal(true);
    } catch(err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  async function onDisableMGIntegration() {
    if(!account) return;

    setDeleteMgApiKeyModal(false);
    setLoading(true);

    try {
      const token = await grecaptcha.execute(ReCaptchaSiteKey, { action: "submit" });

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/account/delete_mg_api_key`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          grecaptcha: token
        })
      });
      const data = await response.json();

      if(!data.success) {
        throw new Error(data.result_message);
      }

      account.mg_key = null;
    } catch(err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={"Account-Setting" + (account.mg_key ? " Mg-Enabled" : "")}>
      <div className="Account-Setting-Left">
        <div className="Account-Setting-Icon">
          <FontAwesomeIcon icon={faKey} />
        </div>
        <div className="Account-Setting-Content">
          <p className="Account-Setting-Title">Moongourd Integration</p>
          <p className="Account-Setting-Value">{account.mg_key ? "Enabled" : "Disabled"}</p>
        </div>
      </div>
      <div className={account.mg_key ? "Mg-Manage Mg-Enabled": "Mg-Manage"}>
        {account.mg_key && <input type="password" className="Form-Input" style={{height: "40px", margin: "0", gridColumn: "1 / span 3"}} value={account.mg_key} disabled ref={mgKeyInputRef}/>}
        {account.mg_key && <NormalActionButton text="Show" width="100%" onClick={(event: React.ChangeEvent<HTMLButtonElement>): void => { 
          if(!mgKeyInputRef.current) return;

          if(mgKeyInputRef.current.type === "text") {
            mgKeyInputRef.current.type = "password";
          } else {
            mgKeyInputRef.current.type = "text";
          }

          event.target.innerText = event.target.innerText === "Show" ? "Hide" : "Show";
        }} />}
        <NormalActionButton text={account.mg_key ? "Regenerate" : "Enable"} width="100%" onClick={() => setCreateMgApiKeyModal(true)} />
        {account.mg_key && <NormalActionButton text="Disable" width="100%" onClick={() => setDeleteMgApiKeyModal(true)} />}
      </div>
      {createMgApiKeyModal && (
        <ConfirmActionModal title={account.mg_key ? "Regenerate Moongourd Key": "Enable Moongourd Integration"} confirmAction={onEnableMGIntegration} denyAction={() => setCreateMgApiKeyModal(false)}>
          <span>Are you sure you want to continue?</span>
        </ConfirmActionModal>
      )}
      {deleteMgApiKeyModal && (
        <ConfirmActionModal title="Disable Moongourd Integration" confirmAction={onDisableMGIntegration} denyAction={() => setDeleteMgApiKeyModal(false)}>
          <span>Are you sure you want to continue?</span>
        </ConfirmActionModal>
      )}
      {showInstructionModal && (
        <ModalWindow title="Moongourd Integration Successful" width="860px" dismiss={() => setShowInstructionModal(false)}>
          <div style={{margin: "10px 0"}}>
            <table width="100%">
              <tr>
                <td>Username:</td>
                <td><input type="text" className="Form-Input Mg-Instr-Input" value={account.username ?? ""} disabled /></td>
              </tr>
              <tr>
                <td>Auth Token:</td>
                <td><input type="text" className="Form-Input Mg-Instr-Input" value={account.mg_key ?? ""} disabled /></td>
              </tr>
              <tr>
                <td>DPS Upload URL:</td>
                <td><input type="text" className="Form-Input Mg-Instr-Input" value={DPS_UPLOAD_URL} disabled /></td>
              </tr>
              <tr>
                <td>Allowed Area URL:</td>
                <td><input type="text" className="Form-Input Mg-Instr-Input" value={ALLOWED_AREA_URL} disabled /></td>
              </tr>
              <tr>
                <td>Glyph Upload URL:</td>
                <td><input type="text" className="Form-Input Mg-Instr-Input" value={GLYPH_UPLOAD_URL} disabled /></td>
              </tr>
            </table>
          </div>
          <hr/>
          <div className="Moongourd-Upload-Instr">
            <h4>What to do next and how to upload?</h4>
            <ol>
              <li>Start a compatible version of ShinraMeter.</li>
              <li>
                <p>When it launches, open the settings window (Click the ShinraMeter icon on your tray)</p>
                <img src={ShinraInstructionPic1} alt="" style={{maxWidth: "80%", margin: "10px 0"}}/>
              </li>
              <li>
                <p>From the options on the left side, click on Server Settings</p></li>
                <img src={ShinraInstructionPic2} alt="" style={{maxWidth: "80%", margin: "10px 0"}}/>
              <li>
                <p>Click the Add Server Button</p>
                <img src={ShinraInstructionPic3} alt="" style={{maxWidth: "80%", margin: "10px 0"}}/>
              </li>
              <li>
                <p>Fill in the inputs with the details above. The end result will look similar to this:</p>
                <img src={ShinraInstructionPic4} alt="" style={{maxWidth: "80%", margin: "10px 0"}}/>
                <p><strong>NOTE:</strong> The URLs may be different than the picture. Refer to the above details for up to date URLs.</p>
              </li>
              <li>Make sure the tick button on the upper left corner is ticked for the DPS server to be enabled.</li>
              <li>All done! Get in a dungeon, clear a boss and check the DPS website for your upload.</li>
            </ol>
          </div>
        </ModalWindow>
      )}
      {loading && <LoadingIcon />}
    </div>
  )
}

type AccountConnectionType = {
  [key in AccountConnection]: {
    background: string;
    icon: string;
    title: string;
  }
};

function AccountConnection({ type }: { type: AccountConnection }) {
  const types: AccountConnectionType = {
    discord: {
      background: "#5865F2",
      icon: DiscordIcon,
      title: "Discord"
    }
  };

  return (
    <div className="Account-Connection">
      <div className="Account-Connection-Icon" style={{ background: types[type].background }}>
        <img src={types[type].icon} alt={types[type].title} />
      </div>
      <div className="Account-Connection-Content">
        <p className="Account-Connection-Title">{types[type].title}</p>
        <p className="Account-Connection-Value">Not Connected</p>
        {// eslint-disable-next-line @typescript-eslint/no-empty-function
          <LowHeightActionButton text="Connect" onClick={() => { }} />}
      </div>
    </div>
  )
}

type ChangeEmailProps = {
  token: string;
  dismiss: () => void;
};

/**
 * Modal for changing the account's email address.
 * This modal is shown after the user has verified their email address.
 * The user must enter their new email address and the verification code sent to that email address.
 * 
 * @warning This code is a mess. Will be refactored later.
 */
function ChangeEmailModal({ token, dismiss }: ChangeEmailProps) {
  const [loading, setLoading] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState<string | null>(null);
  const emailInputRef = React.useRef<FormInputRef>(null);
  const codeInputRef = React.useRef<FormInputRef>(null);
  const submitRef = React.useRef<HTMLButtonElement>(null);
  const account = React.useContext(LoginContext);

  function sendCodeRequestStart() {
    setLoading(true);
  }

  function sendCodeRequestEnd(err?: Error | null) {
    setLoading(false);

    if(err) {
      if(err instanceof RequestVerificationCodeAPIError) {
        switch(err.errorCode) {
          case "INVALID_EMAIL_ADDRESS": {
            emailInputRef.current?.update(err.message);
            if(submitRef.current) submitRef.current.disabled = true;
            break;
          }
        }
      } else {
        setStatusMessage(err.message);
      }
    } else {
      setStatusMessage("Code sent! Check your email.");
    }
  }

  function onEmailChange() {
    // TODO This is not good. Will be refactored later. (Maybe)
    setEmail(emailInputRef.current?.getValue() ?? null);

    if (!submitRef.current || !emailInputRef.current) return;
    emailInputRef.current.update(validateEmail(emailInputRef.current));

    if(account?.email === emailInputRef.current.getValue()) {
      emailInputRef.current.update("New E-Mail address cannot be the same as the current one.");
    }

    onFormInputChange();
  }

  function onVerificationCodeChange() {
    if (!submitRef.current || !codeInputRef.current) return;
    codeInputRef.current.update(validateVerificationCode(codeInputRef.current));

    onFormInputChange();
  }

  function onFormInputChange() {
    if (!submitRef.current) return;

    if (validateEmail(emailInputRef.current as FormInputRef)) {
      submitRef.current.disabled = true;
      return;
    }

    if (validateVerificationCode(codeInputRef.current as FormInputRef)) {
      submitRef.current.disabled = true;
      return;
    }

    if(account?.email === emailInputRef.current?.getValue()) {
      submitRef.current.disabled = true;
      return;
    }

    submitRef.current.disabled = false;
  }

  function onCodeSend() {
    if (!submitRef.current || !emailInputRef.current) return false;

    const error = validateEmail(emailInputRef.current);
    if(error) {
      emailInputRef.current.update(error);
      submitRef.current.disabled = true;
      return false;
    } else {
      emailInputRef.current.update(null);
    }

    if(account?.email === emailInputRef.current.getValue()) {
      emailInputRef.current.update("New E-Mail address cannot be the same as the current one.");
      submitRef.current.disabled = true;
      return false;
    }
  }

  async function onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);

    try {
      const email = emailInputRef.current?.getValue();
      const code = codeInputRef.current?.getValue();
      const purpose_token = event.currentTarget.elements.namedItem("purpose_token") as HTMLInputElement;

      if(!email || !code || !purpose_token) {
        setStatusMessage("Invalid form data.");
        return;
      }

      const token = await grecaptcha.execute(ReCaptchaSiteKey, { action: "submit" });

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/account/change_email`, {
        method: "PATCH",
        credentials: "include",
        headers: {
          "Authorization": `Bearer ${purpose_token.value}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email,
          verification_code: code,
          grecaptcha: token
        })
      });
      const data = await response.json();

      if(!data.success) {
        switch(data.error_code) {
          case "INVALID_EMAIL_ADDRESS": {
            emailInputRef.current?.update(data.result_message);
            if(submitRef.current) submitRef.current.disabled = true;
            break;
          }
          case "INVALID_VERIFICATION_CODE": {
            codeInputRef.current?.update("Invalid Verification Code");
            if(submitRef.current) submitRef.current.disabled = true;
            break;
          }
          default: {
            setStatusMessage(data.result_message);
          }
        }
        return;
      }

      // TODO Make this better
      setStatusMessage("Email changed!");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch(err) {
      setStatusMessage((err as Error).message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <ModalWindow title="Change E-Mail Address" dismiss={dismiss}>
      <form onSubmit={onFormSubmit}>
        <FormInput
          type="email"
          name="email"
          placeholder="New E-Mail Address"
          onChange={onEmailChange}
          ref={emailInputRef} />
        <VerificationCodeInput
          purpose="verify_new_email"
          email={email || undefined}
          sendRequestStart={sendCodeRequestStart}
          sendRequestEnd={sendCodeRequestEnd}
          onChange={onVerificationCodeChange}
          onSendClick={onCodeSend}
          ref={codeInputRef}/>
        <input type="hidden" name="purpose_token" value={token} />
        <FormSubmitButton text="Submit" ref={submitRef} disabled/>
      </form>
      <p className="ReCAPTCHA-Disclaimer">
        This site is protected by reCAPTCHA and the Google&nbsp;
        <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and&nbsp;
        <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</a> apply.
      </p>
      {loading && <LoadingIcon />}
      {statusMessage && <ErrorMessage message={statusMessage} handleDismiss={() => setStatusMessage(null)} />}
    </ModalWindow>
  )
}

/**
 * Modal for changing the account's password.
 * This modal is shown after the user has verified their email address.
 * The user must enter their new password.
 * 
 * @warning This code is a mess. Will be refactored later.
 */
function ChangePasswordModal({ token, dismiss }: ChangeEmailProps) {
  const [loading, setLoading] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState<string | null>(null);
  const passwordInput = React.useRef<FormInputRef>(null);
  const confirmPasswordInput = React.useRef<FormInputRef>(null);
  const submitRef = React.useRef<HTMLButtonElement>(null);

  function onPasswordChange() {
    // TODO This is not good. Will be refactored later. (Maybe)
    if (!submitRef.current || !passwordInput.current) return;
    passwordInput.current.update(validatePassword(passwordInput.current));

    onFormInputChange();
  }

  function onConfirmPasswordChange() {
    if (!submitRef.current || !confirmPasswordInput.current) return;
    confirmPasswordInput.current.update(validateConfirmPassword(
      passwordInput.current as FormInputRef,
      confirmPasswordInput.current as FormInputRef
    ));

    onFormInputChange();
  }

  function onFormInputChange() {
    if (!submitRef.current) return;

    if (validatePassword(passwordInput.current as FormInputRef)) {
      submitRef.current.disabled = true;
      return;
    }

    if (validateConfirmPassword(
      passwordInput.current as FormInputRef,
      confirmPasswordInput.current as FormInputRef)
    ) {
      submitRef.current.disabled = true;
      return;
    }

    submitRef.current.disabled = false;
  }

  async function onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);

    try {
      const password = passwordInput.current?.getValue();
      const purpose_token = event.currentTarget.elements.namedItem("purpose_token") as HTMLInputElement;

      if(!password || !purpose_token) {
        setStatusMessage("Invalid form data.");
        return;
      }

      const token = await grecaptcha.execute(ReCaptchaSiteKey, { action: "submit" });

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/account/change_password`, {
        method: "PATCH",
        credentials: "include",
        headers: {
          "Authorization": `Bearer ${purpose_token.value}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          password,
          grecaptcha: token
        })
      });
      const data = await response.json();

      if(!data.success) {
        switch(data.error_code) {
          case "INVALID_PASSWORD": {
            passwordInput.current?.update(data.result_message);
            if(submitRef.current) submitRef.current.disabled = true;
            break;
          }
          default: {
            setStatusMessage(data.result_message);
          }
        }
        return;
      }

      // TODO Make this better
      setStatusMessage("Password changed successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch(err) {
      setStatusMessage((err as Error).message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <ModalWindow title="Change E-Mail Address" dismiss={dismiss}>
      <form onSubmit={onFormSubmit}>
        <FormInput
          type="password"
          name="password"
          placeholder="New Password"
          onChange={onPasswordChange}
          ref={passwordInput} />
        <FormInput
          type="password"
          name="confirm_password"
          placeholder="Confirm New Password"
          onChange={onConfirmPasswordChange}
          ref={confirmPasswordInput} />
        <input type="hidden" name="purpose_token" value={token} />
        <FormSubmitButton text="Submit" ref={submitRef} disabled/>
      </form>
      <p className="ReCAPTCHA-Disclaimer">
        This site is protected by reCAPTCHA and the Google&nbsp;
        <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and&nbsp;
        <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</a> apply.
      </p>
      {loading && <LoadingIcon />}
      {statusMessage && <ErrorMessage message={statusMessage} handleDismiss={() => setStatusMessage(null)} />}
    </ModalWindow>
  )
}