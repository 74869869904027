import React from "react";

export const FormInput = React.forwardRef<FormInputRef, InputProps>(
  function FormInputElement({ type, name, placeholder, onChange }, ref) {
    const [err, setErr] = React.useState<string | null>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useImperativeHandle(ref, (): FormInputRef => {
      return {
        getValue: () => inputRef.current?.value,
        update: (msg: string | null) => setErr(msg)
      };
    }, []);

    return (
      <>
        <input
          type={type ?? "text"}
          name={name}
          className="Form-Input"
          style={{ borderColor: (err ? "#ff667f" : undefined) }}
          placeholder={placeholder}
          ref={inputRef}
          onChange={onChange} />
        {err && <p className="Input-Error">{err}</p>}
      </>
    );
  }
);

export const VerificationCodeInput = React.forwardRef<FormInputRef, VerificationCodeInputProps>(
  function VerificationCodeInputElement({ purpose, email, sendRequestStart, sendRequestEnd, onChange, onSendClick }, ref) {
    const [sendCooldown, setSendCooldown] = React.useState<number>(0);
    const [inputError, setInputError] = React.useState<string | null>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useImperativeHandle(ref, (): FormInputRef => {
      return {
        getValue: () => inputRef.current?.value,
        update: (msg: string | null) => setInputError(msg)
      };
    }, []);

    React.useEffect(() => {
      if (sendCooldown === 0) return;
      setTimeout(() => setSendCooldown((prev) => prev - 1), 1000);
    }, [sendCooldown]);

    async function handleSendClick() {
      if(onSendClick && onSendClick() === false) return;

      sendRequestStart();

      try {
        const token = await grecaptcha.execute(ReCaptchaSiteKey, { action: "submit" });

        const url = new URL(`${process.env.REACT_APP_API_BASE_URL}/account/retrieve_verification_code`);
        url.searchParams.append("t", token);
        url.searchParams.append("purpose", purpose);

        if (purpose === "verify_new_email") {
          if (email) {
            url.searchParams.append("email", email);
          } else {
            throw new Error("Email Address is required.");
          }
        }

        const response = await fetch(url, { credentials: 'include' });
        const data = await response.json();

        if (!data.success) {
          throw new RequestVerificationCodeAPIError(data.result_message, data.error_code);
        }

        sendRequestEnd(null);
        setSendCooldown(60);
      } catch (err) {
        sendRequestEnd(err as Error);
      }
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const value = event.target.value;

      if (value.length > 8) {
        event.target.value = value.slice(0, 8);
      }

      setInputError(null);

      if (onChange) {
        onChange(event);
      }
    }

    return (
      <>
        <div className="Form-Input" style={{ border: "none", padding: "0", position: "relative" }}>
          <input
            type="number"
            name="verification_code"
            className="Email-Code-Input"
            style={{ borderColor: (inputError ? "#ff667f" : undefined) }}
            placeholder="Verification Code"
            ref={inputRef}
            onChange={handleChange} />
          <button
            type="button"
            className="Email-Code-Send-Button"
            disabled={sendCooldown !== 0}
            onClick={() => grecaptcha.ready(handleSendClick)}>
            {sendCooldown !== 0 ? `Code Sent (${sendCooldown}s)` : "Get Code"}
          </button>
        </div>
        {inputError && <p className="Input-Error">{inputError}</p>}
      </>
    );
  }
);

export class RequestVerificationCodeAPIError extends Error {
  public errorCode?: string;

  constructor(message: string, errorCode?: string) {
    super(message);
    this.errorCode = errorCode;
  }
}