import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

declare global {
    // eslint-disable-next-line no-var
    var ReCaptchaSiteKey: string;
}

globalThis.ReCaptchaSiteKey = "6LfjxeAmAAAAAGvPRnYJcIAMttzUQb-1_1MWuaxA";

const root = document.getElementById('root') as HTMLElement;
ReactDOM.createRoot(root).render(<App/>);