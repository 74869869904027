import React from "react";
import './Header.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronDown, faChevronUp, faUser } from "@fortawesome/free-solid-svg-icons";
import { LoginContext } from "./Contexts";
import Logo from './Resources/logo.png';

type HeaderProps = {
  logoutAction: () => void;
};

export default function Header({ logoutAction }: HeaderProps) {
  const [dropdownVisible, setDropdownVisible] = React.useState<boolean>(false);
  const [sideMenuVisible, setSideMenuVisible] = React.useState<boolean>(false);
  const account = React.useContext<AccountDetails | null>(LoginContext);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const sideMenuBgRef = React.useRef<HTMLDivElement>(null);
  const sideMenuRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function ClickHandler(event: MouseEvent) {
      if (!menuRef.current?.contains(event.target as Node)) {
        setDropdownVisible(false);
      }
    }

    document.addEventListener('click', ClickHandler);
    return () => document.removeEventListener('click', ClickHandler);
  });

  function onClick() {
    setDropdownVisible(!dropdownVisible);
  }

  function onMenuButtonClick() {
    setTimeout(() => {
      if(!sideMenuRef.current || !sideMenuBgRef.current) return;

      sideMenuBgRef.current.style.opacity = !sideMenuVisible ? "1" : "";
      sideMenuRef.current.style.left = !sideMenuVisible ? "0px" : "";
    }, 0);

    if(sideMenuVisible) {
      setTimeout(() => setSideMenuVisible(false), 300);
    } else {
      setSideMenuVisible(true);
    }
  }

  return (
    <header className="App-Header">
      <div className="App-Header-Content">
        <nav className="App-Header-Nav">
          {account && <button type="button" className="App-Header-Menu-Button" onClick={onMenuButtonClick}>
            <FontAwesomeIcon icon={faBars} />
          </button>}
          <img src={Logo} alt="MT: The Dream" className="App-Header-Image" />
          {account && (
            <ul className="App-Header-Nav-List">
              <li className="App-Header-Nav-List-Item"><a href="/">Home</a></li>
              <li className="App-Header-Nav-List-Item"><a href="#/donate">Donate</a></li>
              <li className="App-Header-Nav-List-Item"><a href="#/redeemcode">Redeem Code</a></li>
              <li className="App-Header-Nav-List-Item"><a href="#/twitchdrops">Twitch Drops</a></li>
              <li className="App-Header-Nav-List-Item"><a href="//mtdream.net/download">Download Game</a></li>
            </ul>
          )}
        </nav>
        {account && (
          <div className="App-Header-Login-Info">
            <div className="App-Header-Login-Menu-Button" onClick={onClick} ref={menuRef}>
              <FontAwesomeIcon icon={faUser} />
              <p style={{ marginInline: "8px", lineHeight: "1.0" }}>{account.username}</p>
              <FontAwesomeIcon icon={dropdownVisible ? faChevronUp : faChevronDown} size="xs" />
            </div>
            {dropdownVisible && (
              <ul className="App-Header-Login-Menu">
                <li onClick={() => {window.location.href = "/#/account"}}>Account</li>
                <li onClick={logoutAction}>Logout</li>
              </ul>
            )}
          </div>
        )}
      </div>
      {sideMenuVisible && <div className="App-Header-Side-Menu-Wrapper">
        <div className="App-Header-Side-Menu-Background" onClick={onMenuButtonClick} ref={sideMenuBgRef}></div>
        <div className="App-Header-Side-Menu" ref={sideMenuRef}>
          <div className="App-Header-Side-Menu-Logo">
            <img src={Logo} alt="MT: The Dream" style={{width: '100%'}} />
          </div>
          <ul className="App-Header-Side-Menu-List" onClick={onMenuButtonClick}>
            <li className="App-Header-Side-Menu-List-Item"><a href="/">Home</a></li>
            <li className="App-Header-Side-Menu-List-Item"><a href="#/donate">Donate</a></li>
            <li className="App-Header-Side-Menu-List-Item"><a href="#/redeemcode">Redeem Code</a></li>
            <li className="App-Header-Side-Menu-List-Item"><a href="#/twitchdrops">Twitch Drops</a></li>
            <li className="App-Header-Side-Menu-List-Item"><a href="//mtdream.net/download">Download Game</a></li>
          </ul>
        </div>
      </div>}
    </header>
  );
}