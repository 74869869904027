/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import './RegisterWindow.css';
import LoadingIcon from './Components/LoadingIcon';
import ErrorMessage from './Components/ErrorMessage';
import AnimatedCheckmark from './AnimatedCheckmark';

interface InputProps {
  type: string;
  placeholder: string;
  onChange: () => void;
}

interface FormInputRef {
  getValue: () => string | undefined;
  update: (msg?: string) => void;
}

interface VerificationCodeInputProps extends InputProps {
  sendCooldown: number;
  onSendClick: () => void;
}

interface APIPassResetResponse {
  success: boolean;
  result_code: number;
  result_message: string;
  error_code?: ValidationErrorCodes;
  email?: string;
}

const FormInput = React.forwardRef<FormInputRef, InputProps>(
  function FormInputElement({ type, placeholder, onChange }, ref) {
    const [err, setErr] = React.useState<string | null>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useImperativeHandle(ref, (): FormInputRef => {
      return {
        getValue: () => inputRef.current?.value,
        update(msg?: string) {
          setErr(msg ?? null);
        }
      };
    }, []);

    return (
      <>
        <input type={type} className="Form-Input" style={{ borderColor: (err ? "#ff667f" : undefined) }} placeholder={placeholder} ref={inputRef} onChange={onChange} />
        {err && <p className="Input-Error">{err}</p>}
      </>
    );
  }
);

const VerificationCodeInput = React.forwardRef<FormInputRef, VerificationCodeInputProps>(
  function VerificationCodeInputElement({ type, placeholder, sendCooldown, onSendClick, onChange }, ref) {
    const [err, setErr] = React.useState<string | null>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useImperativeHandle(ref, (): FormInputRef => {
      return {
        getValue: () => inputRef.current?.value,
        update(msg?: string) {
          setErr(msg ?? null);
        }
      };
    }, []);

    return (
      <>
        <div className="Form-Input" style={{ border: "none", padding: "0", position: "relative" }}>
          <input
            type={type}
            name="verification_code"
            className="Email-Code-Input"
            style={{ borderColor: (err ? "#ff667f" : undefined) }}
            placeholder={placeholder}
            ref={inputRef}
            onChange={onChange} />
          <button
            type="button"
            className="Email-Code-Send-Button"
            disabled={sendCooldown !== 0}
            onClick={onSendClick}>
            {sendCooldown !== 0 ? `Code Sent (${sendCooldown}s)` : "Get Code"}
          </button>
        </div>
        {err && <p className="Input-Error">{err}</p>}
      </>
    );
  }
);

export default function PasswordResetWindow() {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [statusMsg, setStatusMsg] = React.useState<string | null>(null);
  const [verifyCodeCooldown, setVerifyCodeCooldown] = React.useState<number>(0);
  const [resetSuccess, setResetSuccess] = React.useState<boolean>(false);
  const usernameEmailRef = React.useRef<FormInputRef>(null);
  const verifyCodeRef = React.useRef<FormInputRef>(null);
  const passwordRef = React.useRef<FormInputRef>(null);
  const confirmPasswordRef = React.useRef<FormInputRef>(null);
  const submitRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (verifyCodeCooldown === 0) return;

    setTimeout(() => setVerifyCodeCooldown(verifyCodeCooldown - 1), 1000);
  }, [verifyCodeCooldown]);

  function onSendCodeClick() {
    if (!usernameEmailRef.current || !verifyCodeRef.current) return;
    verifyCodeRef.current.update();

    const validationMsg = validateUsernameEmail(usernameEmailRef.current);
    usernameEmailRef.current.update(validationMsg);
    if (!validationMsg) {
      sendVerificationCode();
    }
  }

  async function sendVerificationCode() {
    setLoading(true);

    try {
      const captchaToken = await grecaptcha.execute(ReCaptchaSiteKey, { action: "password_reset_code" });

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/passwordreset/send_verification_code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username_email: usernameEmailRef.current?.getValue(),
          grecaptcha: captchaToken
        })
      });
      const data: APIPassResetResponse = await response.json();

      if (!data.success) {
        if (data.error_code === "INVALID_USERNAME_OR_EMAIL") {
          usernameEmailRef.current?.update(data.result_message);
          return;
        }

        throw new Error(data.result_message);
      }

      setStatusMsg(`Code Sent to ${data.email}. Check your E-Mail.`);
      setVerifyCodeCooldown(60);
    } catch (err) {
      setStatusMsg((err as Error).message);
    } finally {
      setLoading(false);
    }
  }

  function onUsernameEmailChange() {
    if (!submitRef.current || !usernameEmailRef.current) return;
    usernameEmailRef.current.update(validateUsernameEmail(usernameEmailRef.current));

    onFormInputChange();
  }

  function onVerificationCodeChange() {
    if (!submitRef.current || !verifyCodeRef.current) return;
    verifyCodeRef.current.update(validateVerificationCode(verifyCodeRef.current));

    onFormInputChange();
  }

  function onPasswordChange() {
    if (!submitRef.current || !passwordRef.current) return;
    passwordRef.current.update(validatePassword(passwordRef.current));

    if (confirmPasswordRef.current?.getValue()) {
      onConfirmPasswordChange();
    }

    onFormInputChange();
  }

  function onConfirmPasswordChange() {
    if (!submitRef.current || !passwordRef.current || !confirmPasswordRef.current) return;
    confirmPasswordRef.current.update(
      validateConfirmPassword(passwordRef.current, confirmPasswordRef.current)
    );

    onFormInputChange();
  }

  function onFormInputChange() {
    if (!submitRef.current) return;

    if (validateUsernameEmail(usernameEmailRef.current!)) {
      submitRef.current.disabled = true;
      return;
    }

    if (validateVerificationCode(verifyCodeRef.current!)) {
      submitRef.current.disabled = true;
      return;
    }

    if (validatePassword(passwordRef.current!)) {
      submitRef.current.disabled = true;
      return;
    }

    if (validateConfirmPassword(passwordRef.current!, confirmPasswordRef.current!)) {
      submitRef.current.disabled = true;
      return;
    }

    submitRef.current.disabled = false;
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setLoading(true);
    try {
      const recaptchaToken = await grecaptcha.execute(ReCaptchaSiteKey, { action: 'password_reset' });

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/passwordreset`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username_email: usernameEmailRef.current?.getValue(),
          verification_code: verifyCodeRef.current?.getValue(),
          password: passwordRef.current?.getValue(),
          grecaptcha: recaptchaToken
        })
      });
      const data: APIPassResetResponse = await response.json();

      if (!data.success) {
        switch (data.error_code) {
          case "INVALID_USERNAME_OR_EMAIL": {
            if (submitRef.current) submitRef.current.disabled = true;
            usernameEmailRef.current?.update(data.result_message);
            return;
          }
          case "INVALID_VERIFICATION_CODE": {
            if (submitRef.current) submitRef.current.disabled = true;
            verifyCodeRef.current?.update(data.result_message);
            return;
          }
          case "INVALID_PASSWORD": {
            if (submitRef.current) submitRef.current.disabled = true;
            passwordRef.current?.update(data.result_message);
            return;
          }
          default:
            throw new Error(data.result_message);
        }
      }

      setResetSuccess(true);
    } catch (err) {
      setStatusMsg((err as Error).message);
    } finally {
      setLoading(false);
    }
  }

  if(resetSuccess) {
    return (
      <section className="Register-Success-Wrapper">
        <AnimatedCheckmark/>
        <p className="Register-Success">Password Reset Successful!</p>
        <p style={{ textAlign: "center", fontSize: "14px", marginTop: "16px" }}>Return to <a href="/#/login">Log In</a></p>
      </section>
    );
  }

  return (
    <section className="Login-Window">
      <div className="Login-Title"><h2>Password Reset</h2></div>
      <div className="Login-Content">
        <form onSubmit={handleSubmit}>
          <FormInput type="text" placeholder="Username/E-Mail" ref={usernameEmailRef} onChange={onUsernameEmailChange} />
          <VerificationCodeInput type="number" placeholder="Verification Code" ref={verifyCodeRef} sendCooldown={verifyCodeCooldown} onSendClick={onSendCodeClick} onChange={onVerificationCodeChange} />
          <FormInput type="password" placeholder="Password" ref={passwordRef} onChange={onPasswordChange} />
          <FormInput type="password" placeholder="Confirm Password" ref={confirmPasswordRef} onChange={onConfirmPasswordChange} />
          <button type="submit" className="Form-Submit" ref={submitRef} disabled>Submit</button>
          <p style={{ textAlign: "center", fontSize: "14px" }}>Return to <a href="/#/login">Log In</a></p>
        </form>
      </div>
      {loading && <LoadingIcon />}
      {statusMsg && <ErrorMessage message={statusMsg} handleDismiss={() => setStatusMsg(null)} />}
    </section>
  );
}

function validateUsernameEmail(ref: FormInputRef): string | undefined {
  const username_email = ref.getValue();

  if (!username_email) {
    return "Username or E-Mail Address is required.";
  }
}

function validateVerificationCode(codeRef: FormInputRef): string | undefined {
  const code = codeRef.getValue();
  if (!code) {
    return "Verification Code is required.";
  }

  const codeTester = /^[0-9]{8}$/;
  if (!codeTester.test(code)) {
    return "Invalid verification code.";
  }
}

function validatePassword(passwordRef: FormInputRef): string | undefined {
  const password = passwordRef.getValue();

  if (!password) {
    return "Password is required.";
  }

  if (password.length < 8 || password.length > 32) {
    return "Password must be between 8-32 characters.";
  }

  const passwordTester = /^(?=.*\d)(?=.*[a-z]).{8,}$/;
  if (!passwordTester.test(password)) {
    return "Password must contain at least 1 letter and 1 number.";
  }
}

function validateConfirmPassword(
  passwordRef: FormInputRef,
  confirmPasswordRef: FormInputRef): string | undefined {

  if (passwordRef.getValue() !== confirmPasswordRef.getValue()) {
    return "Passwords do not match.";
  }
}