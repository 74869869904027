import React from "react";
import './ModalWindow.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

type Props = {
    children?: React.ReactNode;
    width?: string;
    height?: string;
    title?: string;
    dismiss: () => void;
};

export default function ModalWindow({ children, width, height, title, dismiss }: Props) {
    return (
        <div className="Modal-Window-Container">
            <div className="Modal-Window" style={{width, height}}>
                <div className="Modal-Window-Header">
                    <h3 className="Modal-Window-Title">{title}</h3>
                    <button className="Modal-Window-Close-Button" onClick={dismiss}>
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                </div>
                <div className="Modal-Window-Content">
                    {children}
                </div>
            </div>
        </div>
    );
}