import React from "react";
import { ConfirmActionButton, NormalActionButton } from "./Buttons";
import ModalWindow from "./ModalWindow";
import './ConfirmActionModal.css';

type ConfirmActionModalProps = {
  title?: string;
  children: React.ReactNode;
  confirmAction: () => void;
  denyAction: () => void;
};

export default function ConfirmActionModal({ title, children, confirmAction, denyAction }: ConfirmActionModalProps) {

  return (
    <ModalWindow title={title} dismiss={denyAction}>
      <div className="Confirm-Action-Modal-Content">{children}</div>
      <div className="Confirm-Action-Modal-Buttons">
        <div style={{ marginInline: "5px", width: "100%" }}>
          <NormalActionButton text="No" width="100%" onClick={denyAction} />
        </div>
        <div style={{ marginInline: "5px", width: "100%" }}>
          <ConfirmActionButton text="Yes" onClick={confirmAction} />
        </div>
      </div>
    </ModalWindow>
  );
}