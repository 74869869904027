import React from 'react';
import './PaymentSuccess.css';
import AnimatedCheckmark from './AnimatedCheckmark';

export default function PaymentSuccess() {
    return (
        <section className="Payment-Success-Container">
        <AnimatedCheckmark/>
        <div className="Payment-Success">
            <p style={{marginBottom: "15px"}}>Donation Succesful!</p>
            <p style={{marginBlock: "15px", fontSize: "14px"}}>Your items will be available to your account shortly. If you are already logged in, make sure you restart your game and check your Item Claim window for Astrum Coin.</p>
            <p style={{marginBlock: "15px", fontSize: "14px"}}>If you are experiencing problems, please create a support ticket on our discord server <a href="https://discord.gg/mtdream" target="_blank" rel="noreferrer">here</a>.</p>
            <p style={{marginTop: "15px", fontSize: "14px"}}>Thank you for your support! &lt;3</p>
        </div>
        <p style={{ textAlign: "center", fontSize: "14px", marginTop: "16px" }}>Return to <a href="/">Account Management</a></p>
        </section>
    );
}