import React from "react";
import { LoginContext } from "./Contexts";
import ErrorMessage from "./Components/ErrorMessage";
import { FormSubmitButton } from "./Components/Buttons";
import ModalWindow from "./Components/ModalWindow";
import LoadingIcon from "./Components/LoadingIcon";
import { VerificationCodeInput } from "./Components/FormInputs";

type EmailVerificationModalProps = {
  purpose: SecurityVerificationPurpose;
  onVerificationSuccess: (token: string) => void;
  dismiss: () => void;
};

export function EmailVerificationModal({ purpose, onVerificationSuccess, dismiss }: EmailVerificationModalProps) {
  const [loading, setLoading] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState<string | null>(null);
  const codeInputRef = React.useRef<FormInputRef>(null);
  const submitRef = React.useRef<HTMLButtonElement>(null);
  const account = React.useContext(LoginContext);

  function sendRequestStart() {
    setLoading(true);
  }

  function sendRequestEnd(err?: Error | null) {
    setLoading(false);

    if (err) {
      setStatusMessage(err.message);
    } else {
      setStatusMessage("Code sent! Check your email.");
    }
  }

  function onCodeInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!submitRef.current) return;

    const value = event.target.value;

    if (value) {
      submitRef.current.disabled = false;
    } else {
      submitRef.current.disabled = true;
    }
  }

  async function onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const value = codeInputRef.current?.getValue();
    if (!value) return;

    setLoading(true);
    codeInputRef.current?.update(null);

    try {
      const token = await grecaptcha.execute(ReCaptchaSiteKey, { action: "submit" });

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/account/submit_verification_code`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          purpose,
          code: value,
          grecaptcha: token
        })
      });

      const data = await response.json();

      if (!data.success) {
        switch (data.error_code) {
          case "INVALID_VERIFICATION_CODE": {
            codeInputRef.current?.update("Invalid verification code");

            if (submitRef.current) {
              submitRef.current.disabled = true;
            }
            break;
          }
          default:
            throw new Error(data.result_message);
        }
        return;
      }

      onVerificationSuccess(data.purpose_token);
    } catch (err) {
      setStatusMessage((err as Error).message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <ModalWindow title="Security Verification" dismiss={dismiss}>
      <p className="Modal-Window-Text">
        <span>Click the send button below to obtain a verification code.
          The code will be sent to your email address.</span>
        <div>{account?.email}</div>
      </p>
      <form onSubmit={onFormSubmit}>
        <VerificationCodeInput
          purpose={purpose}
          sendRequestStart={sendRequestStart}
          sendRequestEnd={sendRequestEnd}
          onChange={onCodeInputChange}
          ref={codeInputRef} />
        <FormSubmitButton text="Submit" ref={submitRef} disabled />
      </form>
      <p className="ReCAPTCHA-Disclaimer">
        This site is protected by reCAPTCHA and the Google&nbsp;
        <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and&nbsp;
        <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</a> apply.
      </p>
      {loading && <LoadingIcon />}
      {statusMessage && <ErrorMessage message={statusMessage} handleDismiss={() => setStatusMessage(null)} />}
    </ModalWindow>
  )
}