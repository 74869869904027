/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import './RegisterWindow.css';
import LoadingIcon from './Components/LoadingIcon';
import ErrorMessage from './Components/ErrorMessage';
import AnimatedCheckmark from './AnimatedCheckmark';

interface InputProps {
  type: string;
  placeholder: string;
  onChange: () => void;
}

interface FormInputRef {
  getValue: () => string | undefined;
  update: (msg?: string) => void;
}

interface TosInputProps {
  onChange: () => void;
}

interface TosInputRef {
  getValue: () => boolean;
}

interface VerificationCodeInputProps extends InputProps {
  sendCooldown: number;
  onSendClick: () => void;
}

interface APIRegisterResponse {
  success: boolean;
  result_code: number;
  result_message: string;
  error_code?: ValidationErrorCodes;
}

const FormInput = React.forwardRef<FormInputRef, InputProps>(
  function FormInputElement({ type, placeholder, onChange }, ref) {
    const [err, setErr] = React.useState<string | null>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useImperativeHandle(ref, (): FormInputRef => {
      return {
        getValue: () => inputRef.current?.value,
        update(msg?: string) {
          setErr(msg ?? null);
        }
      };
    }, []);

    return (
      <>
        <input type={type} className="Form-Input" style={{ borderColor: (err ? "#ff667f" : undefined) }} placeholder={placeholder} ref={inputRef} onChange={onChange} />
        {err && <p className="Input-Error">{err}</p>}
      </>
    );
  }
);

const VerificationCodeInput = React.forwardRef<FormInputRef, VerificationCodeInputProps>(
  function VerificationCodeInputElement({ type, placeholder, sendCooldown, onSendClick, onChange }, ref) {
    const [err, setErr] = React.useState<string | null>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useImperativeHandle(ref, (): FormInputRef => {
      return {
        getValue: () => inputRef.current?.value,
        update(msg?: string) {
          setErr(msg ?? null);
        }
      };
    }, []);

    return (
      <>
        <div className="Form-Input" style={{ border: "none", padding: "0", position: "relative" }}>
          <input
            type={type}
            name="verification_code"
            className="Email-Code-Input"
            style={{ borderColor: (err ? "#ff667f" : undefined) }}
            placeholder={placeholder}
            ref={inputRef}
            onChange={onChange} />
          <button
            type="button"
            className="Email-Code-Send-Button"
            disabled={sendCooldown !== 0}
            onClick={onSendClick}>
            {sendCooldown !== 0 ? `Code Sent (${sendCooldown}s)` : "Get Code"}
          </button>
        </div>
        {err && <p className="Input-Error">{err}</p>}
      </>
    );
  }
);

const TermsFormInput = React.forwardRef<TosInputRef, TosInputProps>(
  function TermsFormInputElement({ onChange }, ref) {
    const [err, setErr] = React.useState<string | null>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useImperativeHandle(ref, (): TosInputRef => {
      return {
        getValue: () => inputRef.current?.checked ?? false,
      };
    }, []);

    function handleChange(msg?: string) {
      if (!inputRef.current) return;

      if (!inputRef.current.checked) {
        setErr(msg ?? "You need to accept the Terms of Service to create an account.");
        inputRef.current.style.outline = "1px solid #ff667f";
      } else {
        setErr(null);
        inputRef.current.style.outline = "";
      }

      onChange();
    }

    return (
      <>
        <input type="checkbox" id="accept_tos" name="accept_tos" className="Accept-Tos-Checkbox" onChange={() => handleChange()} ref={inputRef} />
        <label htmlFor="accept_tos" className="Accept-Tos-Label">I have read and agreed to the <a href="https://mtdream.net/tos" target="_blank" rel="noreferrer">Terms of Service</a>.</label>
        {err && <p className="Input-Error">{err}</p>}
      </>
    );
  }
);

export default function RegisterWindow() {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [statusMsg, setStatusMsg] = React.useState<string | null>(null);
  const [verifyCodeCooldown, setVerifyCodeCooldown] = React.useState<number>(0);
  const [registerCompleted, setRegisterCompleted] = React.useState<boolean>(false);
  const usernameRef = React.useRef<FormInputRef>(null);
  const emailRef = React.useRef<FormInputRef>(null);
  const verifyCodeRef = React.useRef<FormInputRef>(null);
  const passwordRef = React.useRef<FormInputRef>(null);
  const confirmPasswordRef = React.useRef<FormInputRef>(null);
  const tosRef = React.useRef<TosInputRef>(null);
  const submitRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (verifyCodeCooldown === 0) return;

    setTimeout(() => setVerifyCodeCooldown(verifyCodeCooldown - 1), 1000);
  }, [verifyCodeCooldown]);

  function onSendCodeClick() {
    if (!emailRef.current || !verifyCodeRef.current) return;
    verifyCodeRef.current.update();

    const emailValidationMsg = validateEmail(emailRef.current);
    emailRef.current.update(emailValidationMsg);
    if (!emailValidationMsg) {
      sendVerificationCode();
    }
  }

  async function sendVerificationCode() {
    setLoading(true);

    try {
      const captchaToken = await grecaptcha.execute(ReCaptchaSiteKey, { action: "register_code" });

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/register/send_verification_code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: emailRef.current?.getValue(),
          grecaptcha: captchaToken
        })
      });
      const data: APIRegisterResponse = await response.json();

      if (!data.success) {
        if (data.error_code === "INVALID_EMAIL_ADDRESS") {
          emailRef.current?.update(data.result_message);
          return;
        }

        throw new Error(data.result_message);
      }

      setStatusMsg("Code Sent. Check your E-Mail.");
      setVerifyCodeCooldown(60);
    } catch (err) {
      setStatusMsg((err as Error).message);
    } finally {
      setLoading(false);
    }
  }

  function onUsernameChange() {
    if (!submitRef.current || !usernameRef.current) return;
    usernameRef.current.update(validateUsername(usernameRef.current));

    onFormInputChange();
  }

  function onEmailChange() {
    if (!submitRef.current || !emailRef.current) return;
    emailRef.current.update(validateEmail(emailRef.current));

    onFormInputChange();
  }

  function onVerificationCodeChange() {
    if (!submitRef.current || !verifyCodeRef.current) return;
    verifyCodeRef.current.update(validateVerificationCode(verifyCodeRef.current));

    onFormInputChange();
  }

  function onPasswordChange() {
    if (!submitRef.current || !passwordRef.current) return;
    passwordRef.current.update(validatePassword(passwordRef.current));

    if (confirmPasswordRef.current?.getValue()) {
      onConfirmPasswordChange();
    }

    onFormInputChange();
  }

  function onConfirmPasswordChange() {
    if (!submitRef.current || !passwordRef.current || !confirmPasswordRef.current) return;
    confirmPasswordRef.current.update(
      validateConfirmPassword(passwordRef.current, confirmPasswordRef.current)
    );

    onFormInputChange();
  }

  function onFormInputChange() {
    if (!submitRef.current) return;

    if (validateUsername(usernameRef.current!)) {
      submitRef.current.disabled = true;
      return;
    }

    if (validateEmail(emailRef.current!)) {
      submitRef.current.disabled = true;
      return;
    }

    if (validateVerificationCode(verifyCodeRef.current!)) {
      submitRef.current.disabled = true;
      return;
    }

    if (validatePassword(passwordRef.current!)) {
      submitRef.current.disabled = true;
      return;
    }

    if (validateConfirmPassword(passwordRef.current!, confirmPasswordRef.current!)) {
      submitRef.current.disabled = true;
      return;
    }

    if (!tosRef.current?.getValue()) {
      submitRef.current.disabled = true;
      return;
    }

    submitRef.current.disabled = false;
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    usernameRef.current?.update();
    emailRef.current?.update();
    verifyCodeRef.current?.update();
    passwordRef.current?.update();
    confirmPasswordRef.current?.update();

    setLoading(true);
    try {
      const token = await grecaptcha.execute(ReCaptchaSiteKey, { action: "register" });

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: usernameRef.current?.getValue(),
          email: emailRef.current?.getValue(),
          verification_code: verifyCodeRef.current?.getValue(),
          password: passwordRef.current?.getValue(),
          accept_tos: tosRef.current?.getValue(),
          grecaptcha: token
        })
      });
      const data: APIRegisterResponse = await response.json();

      if (!data.success) {
        switch (data.error_code) {
          case "INVALID_USERNAME": {
            if (submitRef.current) submitRef.current.disabled = true;
            usernameRef.current?.update(data.result_message);
            return;
          }
          case "INVALID_EMAIL_ADDRESS": {
            if (submitRef.current) submitRef.current.disabled = true;
            emailRef.current?.update(data.result_message);
            return;
          }
          case "INVALID_VERIFICATION_CODE": {
            if (submitRef.current) submitRef.current.disabled = true;
            verifyCodeRef.current?.update(data.result_message);
            return;
          }
          case "INVALID_PASSWORD": {
            if (submitRef.current) submitRef.current.disabled = true;
            passwordRef.current?.update(data.result_message);
            return;
          }
          case "INVALID_CAPTCHA": {
            if (submitRef.current) submitRef.current.disabled = true;
            setStatusMsg("Human Verification Required.");
            return;
          }
          default:
            throw new Error(data.result_message);
        }
      }

      setRegisterCompleted(true);
    } catch (err) {
      setStatusMsg((err as Error).message);
    } finally {
      setLoading(false);
    }
  }

  if(registerCompleted) {
    return (
      <section className="Register-Success-Wrapper" style={{height: "400px"}}>
        <h2 className="Register-Success-Title">Welcome to The Dream!</h2>
        <AnimatedCheckmark/>
        <p className="Register-Success">Registration Successful! Your account is now created and you are ready to go!</p>
        <a href="https://mtdream.net/download" className="Download-Button">Download Game</a>
        <p style={{ textAlign: "center", fontSize: "14px" }}>Return to <a href="/#/login">Log In</a></p>
      </section>
    );
  }

  return (
    <section className="Login-Window">
      <div className="Login-Title"><h2>Account Register</h2></div>
      <div className="Login-Content">
        <form onSubmit={handleSubmit}>
          <FormInput type="text" placeholder="Username" ref={usernameRef} onChange={onUsernameChange} />
          <FormInput type="email" placeholder="E-Mail" ref={emailRef} onChange={onEmailChange} />
          <VerificationCodeInput type="number" placeholder="Verification Code" ref={verifyCodeRef} sendCooldown={verifyCodeCooldown} onSendClick={onSendCodeClick} onChange={onVerificationCodeChange} />
          <FormInput type="password" placeholder="Password" ref={passwordRef} onChange={onPasswordChange} />
          <FormInput type="password" placeholder="Confirm Password" ref={confirmPasswordRef} onChange={onConfirmPasswordChange} />
          <TermsFormInput ref={tosRef} onChange={onFormInputChange} />
          <button type="submit" className="Form-Submit" ref={submitRef} disabled>Register Now</button>
          <p style={{ textAlign: "center", fontSize: "14px", marginTop: "0", marginBottom: "20px" }}>Already have an account? <a href="/#/login">Log In</a></p>
        </form>
        {/* <div className="Login-Other-Options">
          <p className="Login-Options-Separator">More Login Methods</p>
          <ul className="Login-Options-List">
            <li>
              <a href="https://discord.com/oauth2/authorize?client_id=880294531667476500&redirect_uri=http%3A%2F%2Flocalhost%2Flauncher%2Flogin%2Fdiscord_login&response_type=code&scope=identify%20email">
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjcuMTQgOTYuMzYiPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDojZmZmO308L3N0eWxlPjwvZGVmcz48ZyBpZD0i5Zu+5bGCXzIiIGRhdGEtbmFtZT0i5Zu+5bGCIDIiPjxnIGlkPSJEaXNjb3JkX0xvZ29zIiBkYXRhLW5hbWU9IkRpc2NvcmQgTG9nb3MiPjxnIGlkPSJEaXNjb3JkX0xvZ29fLV9MYXJnZV8tX1doaXRlIiBkYXRhLW5hbWU9IkRpc2NvcmQgTG9nbyAtIExhcmdlIC0gV2hpdGUiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTEwNy43LDguMDdBMTA1LjE1LDEwNS4xNSwwLDAsMCw4MS40NywwYTcyLjA2LDcyLjA2LDAsMCwwLTMuMzYsNi44M0E5Ny42OCw5Ny42OCwwLDAsMCw0OSw2LjgzLDcyLjM3LDcyLjM3LDAsMCwwLDQ1LjY0LDAsMTA1Ljg5LDEwNS44OSwwLDAsMCwxOS4zOSw4LjA5QzIuNzksMzIuNjUtMS43MSw1Ni42LjU0LDgwLjIxaDBBMTA1LjczLDEwNS43MywwLDAsMCwzMi43MSw5Ni4zNiw3Ny43LDc3LjcsMCwwLDAsMzkuNiw4NS4yNWE2OC40Miw2OC40MiwwLDAsMS0xMC44NS01LjE4Yy45MS0uNjYsMS44LTEuMzQsMi42Ni0yYTc1LjU3LDc1LjU3LDAsMCwwLDY0LjMyLDBjLjg3LjcxLDEuNzYsMS4zOSwyLjY2LDJhNjguNjgsNjguNjgsMCwwLDEtMTAuODcsNS4xOSw3Nyw3NywwLDAsMCw2Ljg5LDExLjFBMTA1LjI1LDEwNS4yNSwwLDAsMCwxMjYuNiw4MC4yMmgwQzEyOS4yNCw1Mi44NCwxMjIuMDksMjkuMTEsMTA3LjcsOC4wN1pNNDIuNDUsNjUuNjlDMzYuMTgsNjUuNjksMzEsNjAsMzEsNTNzNS0xMi43NCwxMS40My0xMi43NFM1NCw0Niw1My44OSw1Myw0OC44NCw2NS42OSw0Mi40NSw2NS42OVptNDIuMjQsMEM3OC40MSw2NS42OSw3My4yNSw2MCw3My4yNSw1M3M1LTEyLjc0LDExLjQ0LTEyLjc0Uzk2LjIzLDQ2LDk2LjEyLDUzLDkxLjA4LDY1LjY5LDg0LjY5LDY1LjY5WiIvPjwvZz48L2c+PC9nPjwvc3ZnPg==" className="More-Login-Img" style={{ background: "#5865F2" }} alt="Discord Login" />
              </a>
            </li>
          </ul>
        </div> */}
      </div>
      {loading && <LoadingIcon />}
      {statusMsg && <ErrorMessage message={statusMsg} handleDismiss={() => setStatusMsg(null)} />}
    </section>
  );
}

function validateUsername(usernameRef: FormInputRef): string | undefined {
  const username = usernameRef.getValue();

  if (!username) {
    return "Username is required.";
  }

  if (username.length < 3 || username.length > 16) {
    return "Username must be between 3-16 characters";
  }

  const usernameTester = /^[\w\d]+$/;
  if (!usernameTester.test(username)) {
    return "Username can only contain letters, numbers and underscores (_).";
  }
}

function validateEmail(emailRef: FormInputRef): string | undefined {
  const email = emailRef.getValue();

  if (!email || email.length > 256) {
    return "Invalid E-Mail Address";
  }

  const emailTester = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (!emailTester.test(email)) {
    return "Invalid E-Mail Address";
  }

  const [account, address] = email.split('@');
  if (account.length > 64) {
    return "Invalid E-Mail Address";
  }

  const domainParts = address.split('.');
  if (domainParts.some(function (part: string) {
    return part.length > 63;
  })) {
    return "Invalid E-Mail Address";
  }
}

function validateVerificationCode(codeRef: FormInputRef): string | undefined {
  const code = codeRef.getValue();
  if (!code) {
    return "Verification Code is required.";
  }

  const codeTester = /^[0-9]{8}$/;
  if (!codeTester.test(code)) {
    return "Invalid Verification Code.";
  }
}

function validatePassword(passwordRef: FormInputRef): string | undefined {
  const password = passwordRef.getValue();

  if (!password) {
    return "Password is required.";
  }

  if (password.length < 8 || password.length > 32) {
    return "Password must be between 8-32 characters.";
  }

  const passwordTester = /^(?=.*\d)(?=.*[a-z]).{8,}$/;
  if (!passwordTester.test(password)) {
    return "Password must contain at least 1 letter and 1 number.";
  }
}

function validateConfirmPassword(
  passwordRef: FormInputRef,
  confirmPasswordRef: FormInputRef): string | undefined {

  if (passwordRef.getValue() !== confirmPasswordRef.getValue()) {
    return "Passwords do not match.";
  }
}